
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const defaultMenu = [
      {
        label:'Bệnh Viện Y Học Cổ Truyền Đà Nẵng',
        icon:'pi pi-fw pi-list',
        url: '/mainpage'
      },
    ];
    const dkkbMenu = [
      // {
      //   label:'Đăng ký khám bệnh',
      //   icon:'pi pi-fw pi-heart',
      //   url: 'https://dkkb.bvyhctdn.xyz/'
      // },
      {
        label:'Nghiên cứu khoa học',
        icon:'pi pi-fw pi-book',
        url: 'https://nckh.benhvienyhoccotruyendanang.vn/'
      },
      // {
      //   label:'Khảo sát đánh giá của BN',
      //   icon:'pi pi-fw pi-star-fill',
      //   url: '/evalofpatient'
      // },
      // {
      //   label:'Danh sách đánh giá của BN',
      //   icon:'pi pi-fw pi-list',
      //   url: '/listevalpatient'
      // },
      // {
      //   label:'Hướng dẫn đường đi cho bệnh nhân',
      //   icon:'pi pi-fw pi-video',
      //   url: '/videopage'
      // },
    ];
    const managerMenu = [
      {
        label:'Danh sách',
        icon:'pi pi-fw pi-list',
        url: '/listemploy'
      },
      {
        label:'Kết quả đánh giá',
        icon:'pi pi-fw pi-check-square',
        url: '/result'
      },
    ];
    const adminMenu = [
      {
        label:'Danh sách',
        icon:'pi pi-fw pi-list',
        url: '/listemploy'
      },
      {
        label:'Kết quả đánh giá',
        icon:'pi pi-fw pi-check-square',
        url: '/result'
      },
    ];
    const userMenu = [
      {
        label:'Kết quả đánh giá',
        icon:'pi pi-fw pi-check-square',
        url: '/result'
      },
    ];
    const emailMenu = [
      {
        label:'Thêm thông tin CDT',
        icon:'pi pi-fw pi-plus',
        url: '/directing'
      },
      {
        label:'Gởi chỉ đạo tuyến',
        icon:'pi pi-fw pi-send',
        url: '/sendemail'
      },
      {
        label:'Tạo tài khoản nhận',
        icon:'pi pi-fw pi-id-card',
        url: '/addemail'
      },
      {
        label:'Thống kê chỉ đạo tuyến',
        icon:'pi pi-fw pi-chart-line',
        url: '/statisticalemailpage'
      },
    ];
    const evalCriteriaMenu = [
      {
        label:'Bộ tiêu chí đánh giá',
        icon:'pi pi-fw pi-book',
        url: '/evalteaminfo'
      },
      {
        label:'Đánh giá chất lượng',
        icon:'pi pi-fw pi-star',
        url: '/evalteam'
      },
      {
        label:'Thống kê đánh giá CLBV',
        icon:'pi pi-fw pi-chart-line',
        url: '/statisticalevalteam'
      },
      {
        label:'Danh sách đánh giá của người bệnh',
        icon:'pi pi-fw pi-align-justify',
        url: '/listevalpatient'
      },
      {
        label:'Thống kê đánh giá của người bệnh',
        icon:'pi pi-fw pi-chart-line',
        url: '/statisticalevalpatient'
      }
    ];
    const medicalIncidentMenu = [
      {
        label:'Gởi BCSCYK',
        icon:'pi pi-fw pi-file',
        url: '/medicalincident'
      },
      {
        label:'BCSCYK',
        icon:'pi pi-fw pi-download',
        url: '/download'
      },
      {
        label:'Thống kê báo cáo sự cố y khoa',
        icon:'pi pi-fw pi-chart-line',
        url: '/statisticalmedicalincident'
      },
    ];
    // const patientMenu = [
    //   {
    //     label:'Thống kê đánh giá NB',
    //     icon:'pi pi-fw pi-chart-line',
    //     url: '/statisticalevalpatient'
    //   }
    // ];
    const settingMenu = [
      {
        label:'Danh sách tài khoản',
        icon:'pi pi-fw pi-users',
        url: '/userlistinfo'
      },
      {
        label:'Đăng ký tài khoản mới',
        icon:'pi pi-fw pi-user-plus',
        url: '/register'
      }
    ];
    const emailTab = {
      label:'Chỉ Đạo',
      icon:'pi pi-fw pi-envelope',
      items: emailMenu
    };
    // const patientTab = {
    //   label:'Người Bệnh',
    //   icon:'pi pi-fw pi-user',
    //   items: patientMenu
    // };
    const evalCriteriaTab = {
      label:'Đánh Giá CLBV',
      icon:'pi pi-fw pi-check-square',
      items: evalCriteriaMenu
    };
    const settingAccount = {
      label:'Cấu Hình',
      icon:'pi pi-fw pi-cog',
      items: settingMenu
    };
    const appMenu = computed(() => {
        if(store.state.permission == "Admin") return adminMenu;
        else if(store.state.permission == "Employee") return userMenu;
         else return managerMenu;
    });
    const homeMenu = computed(() => {
      const menu = ref([
        {
          label:'',
          icon:'pi pi-fw pi-home',
          items: defaultMenu
        },
      ]);

      if(props.isLoggedIn)
      {

        if(store.state.permission == "Admin") {
          menu.value.push({
            label:'Đánh Giá Nhân Viên',
            icon:'pi pi-fw pi-check-circle',
            items: adminMenu
          });
        }
        else if(store.state.permission == "Employee") {
          menu.value.push({
            label:'Đánh Giá Nhân Viên',
            icon:'pi pi-fw pi-check-circle',
            items: userMenu
          });
        }
        else {
          menu.value.push({
            label:'Đánh Giá Nhân Viên',
            icon:'pi pi-fw pi-check-circle',
            items: managerMenu
          });
        }

        menu.value.push({
            label:'BC Sự Cố Y Khoa',
            icon:'pi pi-fw pi-briefcase',
            items: medicalIncidentMenu
          })
      }
      if(store.state.emailPermission == "yes")
      {
        menu.value.push(emailTab);
      }
      if(store.state.permission == "Admin" || store.state.permission == "Director")
      {
        menu.value.push(evalCriteriaTab);
        // menu.value.push(patientTab);
        menu.value.push(settingAccount);
      }


      menu.value.push({
        label:'Mở rộng',
        icon:'pi pi-fw pi-sitemap',
        items: dkkbMenu
      });
      return menu.value;
    })
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      store.dispatch('clearTeam');
      store.dispatch('clearFullName');
      store.dispatch('clearEmailPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenu,
      logout,
      homeMenu
    }
  }
}

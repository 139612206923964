<template>
  <div class="app-container">
    <Toast />
     <TopNavBar :isLoggedIn="isLoggedIn"/>
    <div class="app-content">
      <router-view/>
    </div>
    <div>
      <img alt="logo" src="kclco.png" height="30" class="">
      <b height="30" style="alignment: center; color: dimgray">© Coypyright by KCL Company</b>
    </div>
  </div>
  <div>
  </div>
</template>


<!--<style lang="scss">-->
<!--.app-container {-->
<!-- margin: 0 auto;-->
<!-- max-width: 1400px;-->
<!-- width: 100%;-->
<!-- height: max-content;-->
<!--background: #F7F8F9;-->
<!--  .app-content {-->
<!--   margin: 8px;-->
<!--  }-->
<!--}-->
<!--</style>-->
<style lang="scss">
@import 'assets/style.css';
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  components: {TopNavBar},
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);

    return {
      isLoggedIn
    }
  }
}
</script>
